import React, {Component, Suspense} from "react";
import {withRouter} from "react-router-dom";
import Tagline from "./Tagline";
import TokenPrice from "../../components/TokenPrice";

const Topbar = React.lazy(() => import("./Topbar"));
const FooterWithoutMenuLightSocialOnly = React.lazy(() =>
	import("./FooterWithoutMenuLightSocialOnly")
);

const CryptoTagline = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 text-center">
					<ul className="list-inline mb-0">
						<li className="list-inline-item px-2 mb-0">
							<b>LAYER</b>{" "}
							<TokenPrice address={process.env.REACT_APP_SOL_ADDRESS} chain="avalanche" />
						</li>
						<b className="text-secondary">|</b>
						<li className="list-inline-item px-2 mb-0">
							<b>wAVAX</b>{" "}
							<TokenPrice
								address="0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
								chain="avalanche"
							/>
						</li>
						<b className="text-secondary">|</b>
						<li className="list-inline-item px-2 mb-0">
							<b>wBTC</b>{" "}
							<TokenPrice
								address="0x50b7545627a5162F82A992c33b87aDc75187B218"
								chain="avalanche"
							/>
						</li>
						<b className="text-secondary">|</b>
						<li className="list-inline-item px-2 mb-0">
							<b>wMEMO</b>{" "}
							<TokenPrice
								address="0x0da67235dD5787D67955420C84ca1cEcd4E5Bb3b"
								chain="avalanche"
							/>
						</li>
						<b className="text-secondary">|</b>
						<li className="list-inline-item px-2 mb-0">
							<b>BNB</b>{" "}
							<TokenPrice
								address="0x264c1383EA520f73dd837F915ef3a732e204a493"
								chain="avalanche"
							/>
						</li>
						<b className="text-secondary">|</b>
						<li className="list-inline-item px-2 mb-0">
							<b>gOHM</b>{" "}
							<TokenPrice
								address="0x321E7092a180BB43555132ec53AaA65a5bF84251"
								chain="avalanche"
							/>
						</li>
						<b className="text-secondary">|</b>
						<li className="list-inline-item px-2 mb-0">
							<b>wETH</b>{" "}
							<TokenPrice
								address="0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB"
								chain="avalanche"
							/>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

const Loader = () => {
	return (
		<div id="preloader">
			<div id="status">
				<div className="spinner">
					<div className="double-bounce1"></div>
					<div className="double-bounce2"></div>
				</div>
			</div>
		</div>
	);
};

class Layout extends Component {
	render() {
		const tagLineContent =
			this.props.location.pathname === "/nonexistentpage" ? null : (
				<Tagline>
					<CryptoTagline />
				</Tagline>
			);
		return (
			<React.Fragment>
				<Suspense fallback={Loader()}>
					{this.props.location.pathname === "/nonexistentpage" ? (
						<Topbar tagline={tagLineContent} hasDarkTopBar={this.props.hasDarkTopBar} />
					) : (
						<Topbar tagline={tagLineContent} hasDarkTopBar={this.props.hasDarkTopBar} />
					)}

					{this.props.children}
					<FooterWithoutMenuLightSocialOnly className="border-0 bg-light text-dark" />
				</Suspense>
			</React.Fragment>
		);
	}
}

export default withRouter(Layout);