import React from "react";

const Root = React.lazy(() => import("./pages/Home/index"));
const Roadmap = React.lazy(() => import("./pages/Home/Roadmap"));
const Faq = React.lazy(() => import("./pages/Home/Faq"));

const Whitepaper = React.lazy(() => import("./pages/Home/Whitepaper"));
const Marketing = React.lazy(() => import("./pages/Home/Marketing"));

const Dashboard = React.lazy(() => import("./pages/Dashboard/index"));
const Changelog = React.lazy(() => import("./pages/Home/Changelog"));
const Swap = React.lazy(() => import("./pages/Home/Swap"));

const PageMaintenance = React.lazy(() => import("./pages/Special/PageMaintenance"));
const PageError = React.lazy(() => import("./pages/Special/PageError"));
const PageThankYou = React.lazy(() => import("./pages/Special/PageThankYou"));
const PageComingSoon = React.lazy(() => import("./pages/Special/PageComingSoon"));

const routes = [
  { path: "/", component: Root, isTopbarDark: true, exact: true },

  { path: "/dashboard", component: Dashboard},
  { path: "/roadmap", component: Roadmap},
  { path: "/faq", component: Faq},

  { path: "/whitepaper", component: Whitepaper},
  { path: "/marketing", component: Marketing},

  { path: "/changelog", component: Changelog},
  { path: "/swap", component: Swap},

  { path: "/comingsoon", component: PageComingSoon, isWithoutLayout: true, },
  { path: "/error", component: PageError, isWithoutLayout: true },
  { path: "/thanks", component: PageThankYou, isWithoutLayout: true },
  { path: "/maintenance", component: PageMaintenance, isWithoutLayout: true },
  
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;