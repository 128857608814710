import { useState } from "react";
import { useTokenPrice } from "react-moralis";

function TokenPrice(props) {
  const { data: formattedData } = useTokenPrice(props);

  const [isUSDMode, setIsUSDMode] = useState(false);

  const toggleDisplayStyle = () => setIsUSDMode(!isUSDMode);

  return (
      <text style={{ cursor: "pointer" }} onClick={toggleDisplayStyle} title={`Show in ${isUSDMode ? "AVAX" : "USD"}`}>
        {formattedData && (isUSDMode ? formattedData.formattedUsd : formattedData.formattedNative)}
      </text>
  );
}
export default TokenPrice;
